import React, { useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { ThemeOptions, createTheme, ThemeProvider } from '@mui/material';
import palette from '~/theme/palette';
import typography from '~/theme/typography';
import breakpoints from '~/theme/breakpoints';
import shadows, { customShadows } from '~/theme/shadows';
import shape from '~/theme/shape';
import components from '~/theme/components';
import merge from 'deepmerge';
import { DarkModeContext } from '~/utils/contexts';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { readLocalStorage } from '~/utils/storage';
import { useLocation } from 'react-router-dom';

const Theme: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const location = useLocation();
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const isInitialDarkMode = useMemo(() => readLocalStorage('dark_mode'), []);
	const [darkMode, setDarkMode] = useState<boolean>(!!isInitialDarkMode);

	const isLight = darkMode === null ? !prefersDarkMode : !darkMode;
	const themeDirection = 'ltr';

	const themeOptions: ThemeOptions = {
		palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
		shape,
		typography,
		breakpoints,
		direction: themeDirection,
		shadows: isLight ? shadows.light : shadows.dark,
		customShadows,
	};

	const _theme = createTheme({ ...themeOptions });

	const themes: {
		match: RegExp;
		theme: { [x: string]: any };
	}[] = [
		{
			match: /^\/login/,
			theme: {
				...themeOptions,
				palette: {
					background: {
						default: _theme.palette.primary.main,
					},
				},
			},
		},
	];

	const customTheme = themes.find((theme) => theme.match.test(location?.pathname))?.theme ?? {};

	const theme = createTheme(
		merge.all([
			_theme,
			customTheme?.palette?.mode === 'dark'
				? {
						palette: palette.dark,
						shadows: shadows.dark,
				  }
				: customTheme?.palette?.mode === 'light'
				? {
						palette: palette.light,
						shadows: shadows.light,
				  }
				: {},
			customTheme,
		])
	);

	theme.components = merge(components(theme), customTheme?.components ?? {});

	// console.log({ theme });

	return (
		<DarkModeContext.Provider
			value={{
				darkMode: theme.palette.mode === 'dark',
				onToggle: () => setDarkMode(!darkMode),
			}}
		>
			<ThemeProvider theme={theme}>
				<EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
			</ThemeProvider>
		</DarkModeContext.Provider>
	);
};

export default Theme;
