import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = lazy(() => import('../pages/index'));
const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));

const routes: PreRoute[] = [
	{
		path: '/',
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: '/offline',
				element: <Offline />,
			},
		],
	},
	{
		element: <Private />,
		children: [
		],
	},
];

export const navigation = [
	{
		name: 'Home',
		path: '/',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	}
]

export default routes;